import React from "react";
import { graphql } from "gatsby";
import { Banner } from "./HeroBanner-types";
import "components/hero/hero-common.module.scss";
import "./hero.module.scss";

import { HeroContent } from "./HeroContent";
import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint";
import { useColorAreaSettings } from "hooks/useColorAreaSettings";
import { useGeneralSettings } from "hooks/useGeneralSettings";
import { useLogos } from "hooks/queries/settings/useLogos";
interface HeroProps {
  banner: Banner
}

export const HeroBanner = ({ banner }: HeroProps) => {
  const {
    background_image,
    buttons,
    components,
    heading,
    secondary_image,
    sub_heading,
    tagline,
  } = banner;

  const { companyName } = useGeneralSettings();
  const { imageBannerColorOverlay } = useColorAreaSettings();
  const { sideNavLogo } = useLogos();
  const prefix: string = 'fr-hero';

  const highlights = components?.modular_content?.filter(
    component => component?.system?.type === 'highlights_group'
  )[0]?.elements?.highlights?.modular_content;

  const bannerColorOverride = imageBannerColorOverlay
    ? ` ${imageBannerColorOverlay}--backgroundColor`
    : '';

  const backgroundImageCSSVariable = background_image?.value[0]?.url
    ? getBackgroundImageUrlByBreakpoint({
        variableName: 'hero-background-image',
        imageUrl: background_image.value[0].url,
        breakpoints: {
          mobile: {
            width: 1500,
          },
        },
      })
    : {};

  const hasSecondaryImage = secondary_image && secondary_image?.value?.length > 0
      ? true : false;

  const heroClassName =
    hasSecondaryImage
      ? ` ${prefix}--with-image`
      : ` ${prefix}--default`;

  const contentClassName =
    hasSecondaryImage
      ? 'fr-hero__banner-content-with-image'
      : 'fr-hero__banner-content';

  const overrideBackground = imageBannerColorOverlay
    ? ` ${imageBannerColorOverlay}--backgroundOverlayBefore`
    : '';

  return (
    <section
      className={ `${prefix}${heroClassName}${bannerColorOverride}${overrideBackground}` }
      style={ backgroundImageCSSVariable }
    >
      <div
        className={ `${contentClassName} fr-container fr-container--large` }
      >
        { secondary_image?.value.length === 0 && sideNavLogo && (
          <img
            alt={ sideNavLogo.description || `${companyName} Logo` }
            className={ `${prefix}__logo` }
            loading="lazy"
            src={ sideNavLogo.url }
          />
        ) }
        <HeroContent
          baseClassName={ contentClassName }
          buttons={ buttons?.value }
          heading={ heading?.value }
          highlights={ highlights }
          secondaryImage={ secondary_image?.value[0] }
          subHeading={ sub_heading }
          tagline={ tagline }
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HeroBannerFragment on kontent_item_hero_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      tagline {
        value
        type
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      components {
        modular_content {
          ...FormFragment
          ...HighlightGroupFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
